<template>
  <a-modal
    title="重新设定密码"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    cancelText="关闭"
    style="top:20px;"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="工号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input placeholder="工号" v-decorator="[ 'workNo', {}]" :readOnly="true"/>
        </a-form-item>

        <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input placeholder="请输入姓名" v-decorator="[ 'userName', {}]" :readOnly="true"/>
        </a-form-item>

        <a-form-item label="登录密码" :labelCol="labelCol" :wrapperCol="wrapperCol" hasFeedback >
          <a-input type="password" placeholder="请输入登录密码" v-decorator="[ 'password', validatorRules.password]" />
        </a-form-item>

        <a-form-item label="确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol" hasFeedback >
          <a-input type="password" @blur="handleConfirmBlur" placeholder="请重新输入登录密码" v-decorator="[ 'confirmpassword', validatorRules.confirmpassword]"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import api from './user'
import pick from 'lodash.pick'

export default {
  name: 'PasswordModal',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      confirmDirty: false,
      validatorRules: {
        password: {
          rules: [{
            required: true,
            message: '密码由8位数字、大小写字母和特殊符号组成!'
          }, {
            validator: this.validateToNextPassword
          }]
        },
        confirmpassword: {
          rules: [{
            required: true, message: '请重新输入登录密码!'
          }, {
            validator: this.compareToFirstPassword
          }]
        }
      },

      model: {},

      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
  },

  methods: {
    show (record) {
      this.form.resetFields()
      this.visible = true
      this.model.userCode = record.userCode
      this.model.userName = record.userName
      this.model = record
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'userCode', 'userName'))
      })
    },
    close () {
      this.visible = false
    },
    handleSubmit () {
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          const formData = Object.assign(this.model, values)
          api.changPassword(formData).then((res) => {
            this.$message.success(res.message)
            this.$emit('ok')
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    validateToNextPassword  (rule, value, callback) {
      const form = this.form
      const confirmpassword = form.getFieldValue('confirmpassword')
      if (value && confirmpassword && value !== confirmpassword) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('两次输入的密码不一样！')
      }
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword  (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('两次输入的密码不一样！')
      } else {
        callback()
      }
    },
    handleConfirmBlur  (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    }
  }
}
</script>
